import * as React from 'react';

import { Text, BlogImage, BlogLink, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import speechTherapistAndKids from '../../../images/educational-corner/blog24/Logopedi-i-djeca.jpg';
import nonsistematicMistakes from '../../../images/educational-corner/blog24/Nesustavne-i-promjenjive-greske-izgovora-glasova.png';
import therapyCAS from '../../../images/educational-corner/blog24/Terapija-DGA.jpg';

import './index.css';

const ChildhoodApraxiaOfSpeech = () => {
  return (
    <PageNew
      title="KOKOLINGO - Dječja govorna apraksija"
      description="Dječja govorna apraksija (DGA) je neurološki dječji govorni 
      poremećaj u kojem su oštećene preciznost i konzistentnost govornih područja 
      bez neuromišićnih oštećenja koji bi mogli utjecati na reflekse ili mišićni tonus. 
      To znači da ta djeca imaju potrebnu snagu za pokretanje govornih organa 
      (čeljust, usne, jezik, obrazi), da te organe koriste uredno za procese žvakanja i 
      gutanja, čak i za imitiranje negovornih pokreta, ali dolazi do problema kada ih 
      treba u mozgu isplanirati za govornu produkciju."
      headline="Dječja govorna apraksija"
    >
      <Text as="p">
        Kada bi vas netko upitao, što mislite, koja čovjekova aktivnost zahtjeva
        upotrebu najviše mišića, vjerojatno vam ne bi palo na pamet da se radi o
        – govoru. Ne biste vjerovali, ali za izgovor jedne riječi aktivira se
        preko stotinu mišića! A svaki od njih mora se uključiti u točno pravom
        trenutku i određenom snagom i preciznošću da bi se proizveo željeni
        govorni pokret. Uz to, za proizvodnju jedne sekunde govora (2 do 3
        riječi) potrebno je 140 000 živčanih signala (poslanih iz mozga) koji će
        poslati informacije govornim mišićima koliko se jako moraju stegnuti,
        podići, uvinuti i sl. Budući da se ne aktiviraju svi mišići istovremeno,
        mora se osigurati i njihova <i>vremenska organizacija</i>.<br />
        Nije li nevjerojatno kako većina odraslih ljudi i djece govore s lakoćom
        i „bez razmišljanja“?
        <br />
        No što se dogodi kada dijete ima teškoću s planiranjem pokretanja tih
        govornih mišića kako bi proizvelo govor?
      </Text>
      <Text as="h2">Definicija</Text>
      <Text as="p">
        Prema definiciji Američke udruge logopeda (ASHA){' '}
        <b>
          dječja govorna apraksija (DGA) je neurološki dječji govorni poremećaj
          u kojem su oštećene preciznost i konzistentnost govornih područja bez
          neuromišićnih oštećenja koji bi mogli utjecati na reflekse ili mišićni
          tonus
        </b>{' '}
        (preopušten ili prenapet mišić). To znači da ta djeca <b>imaju</b>{' '}
        potrebnu snagu za pokretanje govornih organa (čeljust, usne, jezik,
        obrazi), da te organe koriste uredno za procese žvakanja i gutanja, čak
        i za imitiranje negovornih pokreta (npr. plaženje jezika van ili
        napuhivanje obraza), ali dolazi do problema kada ih treba u mozgu{' '}
        <b>isplanirati</b> za govornu produkciju. Zato će im biti teško
        proizvesti glasove točno i njihov će govor biti vrlo nerazumljiv.
        Pojednostavljeno,{' '}
        <b>
          problem nije u mišićima već u tome kako mozak planira pokretanje
          govornih mišića u svrhu govora
        </b>
        .
      </Text>
      <Text as="h2">Učestalost i nasljeđivanje</Text>
      <Text as="p">
        Pojavljuje se u 1 do 2 slučaja na 1000 djece, a 3 do 4 puta je češća kod
        dječaka nego li kod djevojčica.
      </Text>
      <Text as="p">
        U obitelji djeteta s DGA u više od polovice slučajeva postoje članovi
        koji imaju neki jezično-govorni poremećaj, što znači da nasljeđe ima
        bitnu ulogu u pojavi ove teškoće.
      </Text>
      <Text as="h2">Uzrok</Text>
      <Text as="p">
        Dječja govorna apraksija može nastati kao rezultat vidljivog neurološkog
        oštećenja, kao posljedica neurofunkcionalnog poremećaja poznatog ili
        nepoznatog uzroka, ili može biti nepoznati neurogenski govorni
        poremećaj. Uzrok je u većini slučajeva nepoznat.
      </Text>
      <Text as="h2">Znakovi koji upućuju na DGA</Text>
      <Text as="p">
        Dječja govorna apraksija se ponekad teško prepoznaje jer ima sličnosti s
        artikulacijskim teškoćama, fonološkim teškoćama, razvojnim jezičnim
        poremećajem, dizartrijom i oralnom apraksijom. No postoje tri kriterija
        po kojima ju možemo razlikovati od ostalih teškoća.
        <ol>
          <li>
            <i>Nesustavne i promjenjive greške izgovora glasova.</i>
            <br />
            <Text as="p">
              Mnogi istraživači smatraju da je <b>nesustavnost</b> jedan od
              ključnih pokazatelja DGA-e u diferencijalnoj dijagnostici. Autori
              navode da se nesustavnost i varijabilnost odnose na: različitu
              upotrebu glasa u odnosu na pozicije u riječi, različitu upotrebu
              određenog glasa u različitim riječima unatoč istoj poziciji u
              riječi i različitu upotrebu ciljanog glasa u zadacima višestrukog
              ponavljanja.
            </Text>
            <Text as="p">
              Nesustavne izgovorne greške izrazito utječu na razumljivost
              djetetovog govora. Zašto? Usporedimo dijete s artikulacijskim
              teškoćama i dijete s DGA. Neko dijete s artikulacijskim teškoćama
              primjerice čini zamjene K sa T, G sa D te R sa L. Te su zamjene
              uvijek iste i možemo ih već predvidjeti i na neki način ih u glavi
              već automatski „dekodiramo“ jer postoji sustavno pravilo prema
              kojem dijete čini zamjene. Moći ćemo relativno lako razumjeti
              rečenicu <i>Kraljica nosi krunu na glavi</i>. kada ju to dijete
              izgovori kao <i>Tlaljica nosi tlunu na dlavi</i>. No dijete s DGA
              moglo bi ju ponekad izgovoriti kao:
              <ol id="numbersWithParentheses">
                <li>
                  <i>Tajita noti kunu na javi</i>. ili
                </li>
                <li>
                  <i>Kjajica noci kjunu na dlavi</i>. ili
                </li>
                <li>
                  <i>Kajica nosi junu na lavi</i>
                </li>
              </ol>
            </Text>
          </li>
          <BlogImage
            imgSrc={nonsistematicMistakes}
            imgAlt="Nesustavne i promjenjive greške izgovora glasova"
            imgTitle="Nesustavne i promjenjive greške izgovora glasova"
          />
          <li>
            <i>
              Produžena i otežana koartikulacijska tranzicija između glasova i
              slogova.
            </i>
            <br />
            <Text as="p">
              Problemi u tranziciji najvidljiviji su u{' '}
              <b>koartikulacijskoj tranziciji</b> između glasova i slogova
              unutar određene riječi. Koartikulacija znači da se glasovi koji se
              tvore ne izgovaraju potpuno sukcesivno, jedan za drugim. Oni se
              međusobno stapaju, preklapaju i pri tom racionaliziraju pokrete
              artikulatora samo na najnužnije.
            </Text>
            <Text as="p">
              Pojasnimo ovu kompliciranu definiciju na jednostavnom primjeru.
              Glas N izgovaramo podižući vrh jezika iza gornjih sjekutića,
              puštajući glas kroz nosnu šupljinu. A sada pokušajte izgovoriti
              ime <i>Franka</i>. Je li vam se vrh jezika zaista podigao pri
              izgovoru glasa N? Nije, ostao je spušten jer je iza njega glas K
              koji se izgovara sa spuštenim vrhom jezika, no N je ipak zadržao
              svoje svojstvo nazalnosti pa je stoga ostao prepoznatljiv kao
              takav. Dakle, glasovi unutar riječi mijenjaju način izgovaranja
              ovisno o tome koji ga drugi glasovi okružuju.
            </Text>
            <Text as="p">
              Jedan istraživač navodi da na temelju onoga kako se stvarno odvija
              izgovaranje naravnoga govora treba pretpostaviti da motorički
              centri unaprijed planiraju izgovor ne jednog po jednog glasnika,
              nego čitave govorne riječi odjednom. Uz sva ta objašnjenja ne
              iznenađuje činjenica da će djeca s DGA-om imati poteškoće u
              koartikulaciji odnosno u tranziciji glasova i slogova s obzirom na
              njihov temeljni problem u vremenskom planiranju i sekvencioniranju
              motoričkih pokreta potrebnih za govor.
            </Text>
          </li>
          <li>
            <i>
              Neadekvatna prozodija koja je posebice izražena u naglascima
              pojedine riječi i rečeničnoj intonaciji.
            </i>
            <br />
            <Text as="p">
              U istraživačkim radovima bilježe se varijacije u međuodnosima
              prozodijskih parametara, uključujući produljene glasove i
              produljene pauze između glasova, slogova ili riječi što slušaču
              često ostavlja dojam <b>«staccato» (isprekidanog) govora</b> pri
              čemu se glasovi, slogovi i riječi proizvode kao nezavisne čestice
              izostavljajući laganu tranziciju prema ostalim strukturama.
              <br />
              Kao i u ostalim motoričkim govornim poremećajima reduciran je
              raspon varijacija u visini glasa te raspon u varijaciji
              intenziteta, što ostavlja slušaču dojam da se radi o{' '}
              <b>monotonom govoru</b>.<br />
              Varijabilnost u nazalnoj rezonanciji (nekad hiponazalnost, a nekad
              hipernazalnost) također je zabilježena kao karakteristika DGA.
            </Text>
          </li>
        </ol>
      </Text>
      <Text as="h2">Prvi mogući znakovi DGA</Text>
      <Text as="p">
        Jedni su istraživači proučavali najranije vokalizacije, analizirajući
        retrospektivno video materijale dječjih vokalizacija od rođenja do druge
        godine. Došli su do rezultata kako su djeca s dijagnozom DGA bila{' '}
        <b>
          puno manje rječita, raspolagala s puno manjim fonetskim znanjem te
          konsonante usvajala nešto kasnije
        </b>{' '}
        u usporedbi s djecom urednog razvoja i djecom s drugim jezično-govornim
        teškoćama. Rezultati su bili slični i u drugom istraživanju pa tako
        većina roditelja navodi kako su njihova djeca jako malo vokalizirala i
        brbljala, a <b>brbljanje se javilo puno kasnije</b> nego kod urednog
        razvoja (11. mjesec). Posljedično se <b>kasnije javila prva riječ</b>, a{' '}
        <b>ekspresivni rječnik u 18. i 24. mjesecu je bio puno manji</b>.
      </Text>
      <Text as="h2">Terapija DGA</Text>
      <Text as="p">
        Postoji više različitih logopedskih terapijskih pristupa, neki od njih
        su:
      </Text>
      <Text as="p">
        Pristup koji se temelji na <b>motoričkom učenju</b> koji uključuje:
        <ul>
          <li>imitaciju govornih struktura (slogovi, riječi, rečenice)</li>
          <li>
            poticanje uredne prozodije (pravilno naglašavanje slogova, ritma i
            intonacije)
          </li>
          <li>
            integraciju usvojenih struktura i korištenje istih u spontanoj
            komunikaciji
          </li>
          <li>
            međusobno kombiniranje usvojenih riječi s ciljem širenja iskaza
            (npr. uparivanje dvije riječi u dvočlani iskaz).
          </li>
        </ul>
      </Text>
      <Text as="p">
        Poticanje <b>fonološke svjesnosti</b>
        <ul>
          <li>
            naglasak je na usvajanju jezičnih vještina za djecu čije su
            fonološke reprezentacije slabije
          </li>
          <li>
            namijenjeno starijoj djeci predškolske dobi čija metajezična znanja
            omogućuju složenije aktivnosti
          </li>
        </ul>
      </Text>
      <Text as="p">
        Program <b>glazbenih stimulacija</b>
        <ul>
          <li>
            ovisno o dobi i jezičnom znanju djeteta kreće se s određenim
            govornim strukturama, koje se uvježbavaju „pjevajućim govorom“
          </li>
          <li>
            zbog sporijeg tempa, pravilnijeg ritma i naglašenije intonacije koje
            karakteriziraju pjevanje te bolje koordinacije disanja i
            artikulacije ova tehnika je idealna za poticanje male djece s DGA
          </li>
        </ul>
      </Text>
      <Text as="p">
        Program <b>taktilnih stimulacija</b>
        <ul>
          <li>uključuje uparivanje jezičnih struktura i taktilnih podražaja</li>
          <li>
            svaki glas koji se usvaja karakteriziran je određenim pokretom
            prsta/ruke koji se izvodi na djetetovom licu ili vratu istovremeno s
            izgovaranjem govornog podražaja
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={therapyCAS}
        imgAlt="Terapija DGA"
        imgTitle="Terapija DGA"
      />
      <Text as="p">
        Od izrazite je važnosti da djeca s DGA{' '}
        <b>što ranije krenu na redovite logopedske terapije</b> te da roditelji
        s djetetom{' '}
        <b>kod kuće svakodnevno provode vježbe prema uputama logopeda</b>. Za
        odlazak logopedu nikada nije prerano. Dapače, što je dijete mlađe, veća
        je mogućnost potpunog uklanjanja teškoća.
      </Text>
      <Text as="p">
        Terapija DGA dugotrajnija je i složenija od terapija drugih govornih
        poremećaja. Važno je da dijete ostane cijelo vrijeme motivirano za rad,
        ne samo na govornim vježbama kod logopeda, već posebice kod kuće.{' '}
        <BlogLink>Kokolingo</BlogLink> se pokazao kao izvrstan alat za pomoć u
        uklanjanju teškoća kod DGA jer sadrži i vježbe jezične pokretljivosti,
        ponavljanje slogova, te jednostavnih i složenijih riječi, što znači da
        se može ukomponirati u razne faze govorne terapije.
      </Text>
      <BlogImage
        imgSrc={speechTherapistAndKids}
        imgAlt="Djeca vjezbaju sa logopedom"
        imgTitle="Djeca vjezbaju sa logopedom"
      />
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Blaži, Opačak (2011).{' '}
            <i>
              Teorijski prikaz dječje govorne apraksije i ostalih jezično –
              govornih poremećaja na temelju diferencijalno – dijagnostičkih
              parametara
            </i>
            . Pregledni rad. Edukacijsko - rehabilitacijski fakultet, Zagreb.
          </li>
          <li>
            Pletikosić (2020).{' '}
            <i>
              Motoričke vještine predškolske djece sa sumnjom na dijagnozu
              dječje govorne apraksije
            </i>
            . Diplomski rad. Edukacijsko- rehabilitacijski fakultet, Zagreb.
          </li>
          <li>
            Perković Franjić (2018).{' '}
            <i>
              Logopedska intervencija u djeteta s dječjom govornom apraksijom.
              Specijalistički rad
            </i>
            . Edukacijsko- rehabilitacijski fakultet, Zagreb.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default ChildhoodApraxiaOfSpeech;
